import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import './App.css';
import ParticlesComponent from './particles';
import { TypeAnimation } from 'react-type-animation';
import pythonIcon from './assets/icons/python.png';
import electronIcon from './assets/icons/electron.png';
import htmlIcon from './assets/icons/html.png';
import javascriptIcon from './assets/icons/javascript.png';
import flaskIcon from './assets/icons/flask.png';
import cssIcon from './assets/icons/css.png';
import swiftUIIcon from './assets/icons/swiftUI.png';
import reactIcon from './assets/icons/react.png';
import gitIcon from './assets/icons/git.png';
import devIcon from './assets/icons/devicon.png';
import useIntersectionObserver from './IntersectionObserver';

const Container = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: 100vh;
  scroll-behavior: smooth;
  position: relative; 
`;

const ParticleWrapper = styled.div`
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; 
  pointer-events: none;
`;

const NavLinks = styled.div`
  position: absolute;
  top: 20px;
  right: 50px;
  z-index: 2;
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 0.8rem;
  margin-left: 20px;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
  @media (max-width: 768px) {
    font-size: 0.5rem;
  }
`;

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const slideInFromLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
};

const iconAnimation = {
  hidden: { opacity: 0 },
  visible: (i) => ({
    opacity: 1,
    transition: { delay: i * 0.3, duration: 0.5 },
  }),
};

const IconWrapper = styled(motion.div)`
  position: absolute;
  border-radius: 12px;
  padding: 1rem;
  width: 80px;
  height: 80px;
  display: flex;
  margin-left: -10%;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  transform-origin: center;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
`;

const Icon = styled.img`
  width: 70px;
  height: 70px;
  object-fit: contain;
`;

const IconGrid = styled.div`
  position: relative;
  width: 500px;
  height: 500px;
  margin-right: 5%;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 5%;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
`;

const TextBox = styled(motion.div)`
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  text-align: left;
`;

const TechStack = () => {
  const techIcons = [
    { name: 'Python', src: pythonIcon },
    { name: 'Electron', src: electronIcon },
    { name: 'HTML', src: htmlIcon },
    { name: 'JavaScript', src: javascriptIcon }, 
    { name: 'Flask', src: flaskIcon },
    { name: 'CSS', src: cssIcon },
    { name: 'SwiftUI', src: swiftUIIcon },
    { name: 'React', src: reactIcon },
    { name: 'Git', src: gitIcon },
  ];

  return (
    <IconGrid>
      {techIcons.map((tech, index) => {
        const angle = (index * 2 * Math.PI) / techIcons.length;
        const radius = 240; 
        const left = radius * Math.cos(angle) + 160; 
        const top = radius * Math.sin(angle) + 160;

        return (
          <IconWrapper
            key={tech.name}
            custom={index}
            initial="hidden"
            animate="visible"
            variants={iconAnimation}
            style={{
              left: `${left}px`,
              top: `${top}px`,
            }}
          >
            <Icon src={tech.src} alt={tech.name} />
          </IconWrapper>
        );
      })}
    </IconGrid>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: ${({ bgColor }) => bgColor || '#fff'};
  color: #fff;
  scroll-snap-align: start;
`;

const Heading = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
  text-align: center;
`;

const MainHeading = () => (
  <TypeAnimation
    cursor={true}
    sequence={['Hallo!', 1000, 'Ich bin Dominik.', 1000, 'Softwareentwickler.', 1000]}
    className="main-heading unselectable"
  />
);

const SubMainHeading = () => (
  <TypeAnimation
    cursor={true}
    sequence={['Hello!', 1000, 'I am Dominik. ', 1000, 'Software engineer.', 7000, 'good to see you :)', 1000]}
    className="sub-main-heading unselectable"
  />
);

const AboutMeHeading = () => (
  <TypeAnimation 
  cursor={true}
  sequence={['über mich', 8000, '', 4000, 'about me', 8000]}
  repeat={Infinity}
  className="about-me-heading unselectable"
/>
);

const AboutMeIcon = styled(motion.img)`
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin-top: 20px;
  
`;

const Text = styled.p`
  font-family: 'Montserrat', serif;
  font-size: 1.1rem;
  max-width: 600px;
  text-align: center;
  line-height: 1.6;
`;

function App() {
  const [setRef, isIntersecting] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <Container>
      <ParticleWrapper>
        <ParticlesComponent/>
      </ParticleWrapper>
       <Section bgColor="#282c34">
       <NavLinks>
           <StyledLink href="/impressum">impressum</StyledLink>
           <StyledLink href="/datenschutz">datenschutz</StyledLink>
          </NavLinks>
        <motion.div 
          initial="hidden" 
          animate="visible" 
          variants={fadeIn}
          style={{
            position: 'relative',
            zIndex: 2
          }}
        >
          <MainHeading />
          <SubMainHeading />
        </motion.div>
      </Section>
      <Section bgColor="#242333" ref={setRef}>
        <motion.div 
        initial="hidden" 
        animate={isIntersecting ? "visible" : "hidden"} 
        style={{
            position: 'relative',
            zIndex: 2
          }}>
          <AboutMeHeading />
          <ContentWrapper>
            <LeftColumn>
              <AboutMeIcon
                src={devIcon}
                alt="Developer Icon"
                variants={slideInFromLeft}
                initial="hidden"
                animate={isIntersecting ? "visible" : "hidden"}
              />
              <TextBox
                variants={slideInFromLeft}
                initial="hidden"
                animate={isIntersecting ? "visible" : "hidden"}
              >
                Ich arbeite gerne mit diesen Frameworks und Programmiersprachen. Ich versuche, mich stetig weiterzuentwickeln
                 und neue Trends und Tools zu lernen, um meine Fähigkeiten kontinuierlich zu verbessern. Auch 
                 wenn ich mich noch am Anfang meiner Karriere befinde, bin ich motiviert und offen, mich neuen 
                 Herausforderungen zu stellen und mein Wissen in realen Projekten anzuwenden. Ich bin überzeugt, 
                dass kontinuierliches Lernen und die Bereitschaft, Neues auszuprobieren, wichtig ist, um in 
                der Welt der Softwareentwicklung erfolgreich zu sein.
              </TextBox>
            </LeftColumn>
            <TechStack />
          </ContentWrapper>
        </motion.div>
      </Section>
    </Container>
  );
}

export default App;